const mobileApplication = {
    kavappDelivery:{
        text1:"Kavapp Delivery це мобільний застосункок, розроблений для управління логістикою товарів від складу до торгової точки.",
        text2:"Програма сумісна з операційними системами iOS та Android.",
        text3:"Цей продукт є частиною більшої системи автоматизації для кав'ярень під назвою Kavapp."
    },
    kavappSeller:
        {
            text1:"Kavapp Seller - це мобільний застосунок для автоматизації роботи кав'ярень, призначений для реєстрації продаж та контролю діяльності співробітників.",
            text2:"Сумісний з операційними системами Android, iOS та Windows.",
            text3:"Застосунок є частиною системи автоматизації кав'ярень Kavapp."
        },
    coffeeMobile:
        {
            text1:"Мобільний застосунок розроблений для клієнтів мережі кав'ярень Coffee Mobile.",
            text2:"Метою програми є зручна та зрозуміла дисконтна система для клієнтів компанії. Застосунок має індивідуальний дизайн, зручний інтерфейс та великий функціонал. ",
        },
    drivingRules:
        {
            text1:"Мобільний застосунок розроблений на іOS та Android, призначений полегшити вивчення правил дорожнього руху для подальшої здачі теоретичного іспиту зі знання ПДР.",
            text2:"Користувачі мають змогу за допомогою тестування вивчити правила дорожнього руху.  Для покращення запам'ятовування інформації реалізовано інтервальне повторення матеріалу.",
        },
     testing:
         {
         text1:"Мобільні програми для iOS та Android, які створені для швидкої та ефективної підготовки до  тестування на знання законодавства. Для прийому на роботу в державні установи та силові структури.",
         text2:"Особливістю даних тестів є можливість вивчення матеріалу за методом Лейтнера.",
     },
    detail: "Детальніше",
    application: "Мобільні застосунки"
};

export default mobileApplication;
