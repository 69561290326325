import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IInitialState, IModalMessage, IWindowsSizes} from "../types/application";

const initialState: IInitialState = {
    windowSizes: {
        width: false,
        height: false,
        isWindowsSmall: false,
        isPhoneSize: false,
        isTabletSize: false,
        isAnimationSize: false
    },
    isOpenNavigationMenu: false,
    modalMessage: {
        isOpen: false,
        message: '',
        isError: false,
    },
    isModal: false,
    countryUser: {'': ''},
}


const applicationSlice = createSlice({
    name: 'application',
    initialState: initialState,
    reducers: {
        saveWindowSizes(state, action: PayloadAction<IWindowsSizes>) {
            state.windowSizes = action.payload
        },
        openNavigationMenu(state) {
            state.isOpenNavigationMenu = true
        },
        closeNavigationMenu(state) {
            state.isOpenNavigationMenu = false
        },
        toggleOpenNavigationMenu(state) {
            state.isOpenNavigationMenu = !state.isOpenNavigationMenu
        },
        openModalMessage(state, action: PayloadAction<IModalMessage>) {
            state.modalMessage = {...action.payload, isOpen: true}
        },
        closeModalMessage(state) {
            state.modalMessage = initialState.modalMessage
        },
        isModalForm(state, action: PayloadAction<boolean>) {
            state.isModal = action.payload
        },
        countryCheck(state, action: PayloadAction<Record<string, string>>) {
            state.countryUser = action.payload
        },
    },
});

export const {
    saveWindowSizes,
    openNavigationMenu,
    closeNavigationMenu,
    toggleOpenNavigationMenu,
    openModalMessage,
    closeModalMessage,
    isModalForm,
    countryCheck
} = applicationSlice.actions;

export default applicationSlice.reducer;