const portfolio = {
    kavapp: {
        list: [
            {text: 'Особистий кабінет (Admin) – для адміністратора.'},
            {text: 'Програма продажів (Seller) – для баристи.'},
            {text: 'Склад та доставка (Delivery) – для логіста.'}
        ],
        text_accent: 'Kavapp - багатофункціональна комплексна система для автоматизації кав\'ярень, міні-кафе та автокафе.',
        text: 'Система розділена на 3 категорії:',

        page: {
            list: [
                {text: 'Глобальну структуру проєкту, розроблено відповідно до бізнес-цілей.'},
                {text: 'Визначено дизайн та функціонал проєкту відповідно до бюджету та умов замовника.'},
                {text: 'Розроблено та впроваджено API для адмін-панелі та мобільних застосунків (з використанням PHP, MySQL).'},
                {text: 'Створено мобільні застосунки KavАpp Seller, KavАpp Delivery (використовуючи Flutter, Dart) та панель адміністратора Kavapp admin (React).'}
            ],
            list2: [
                {text: 'Запроваджено безпеку та шифрування конфіденційних даних.'},
                {text: 'Підключено платіжну систему (за допомогою Liqpay).'},
                {text: 'Реалізовано можливість під’єднання принтерів чеків, оснащених інтерфейсами підключення - Wi-Fi, USB та Bluetooth.'},
                {text: 'Надано доступ до фінансової звітності, розробленої відповідно стандартів Податкової системи України.'}
            ],
            seller: {
                text1: 'Мобільний застосунок для Android, iOS та Windows, мета якого – полегшити і, водночас, контролювати роботу баристи.',
                text2: 'За допомогою програми, співробітник відкриває робочу зміну, реєструє продажі, замовлення та списання інгредієнтів, підтверджує нарахування, закриває зміну тощо. Програма автоматично завантажує з сервера меню, ціни, довідники робітників, товарів, інгредієнтів, а також, завантажує на сервер список фіскальних чеків з проданим товаром та іншу необхідну інформацію. ',
                text3: 'Програма має опцію для обміну миттєвими повідомленнями, та взаємопов\'язана з мобільним застосунком KavАpp Delivery. Так, коли бариста здійснює замовлення, або списання – логісту в KavАpp Delivery приходять сповіщення про вище вказані дії.',
                text4: 'Застосунок може працювати автономно та синхронізуватися при підключенні до інтернету.',
                list1: [
                    {text: 'Розроблено структуру проєкту мобільного застосунку.'},
                    {text: 'Створено дизайн та функціонал проєкту відповідно до бюджету замовника.'},
                    {text: 'Розроблено, протестовано та опубліковано мобільну програму в App Store, Play Market та Microsoft Store.'},
                ],
                list2: [
                    {text: 'Приєднано мобільний застосунок до АРІ, відповідно до методології REST АРІ.'},
                    {text: 'Реалізовано можливість під’єднання принтерів чеків, які оснащені інтерфейсами підключення - Wi-Fi, USB та Bluetooth.'},
                    {text: 'Запроваджено безпеку та шифрування конфіденційних даних.'}
                ],
                text_accent: 'Технології, які використовували: Flutter, Dart, BLoC, Dio, Hive, Drift, Freezed, Firebase, GitHub, Figma, Android Studio, Xсode, REST АРІ.'
            },
            delivery: {
                text1: 'Мобільна програма, для Android та iOS, призначена для контролю руху товарів від складу до кав’ярні, а також усього, що стосується складської логістики.',
                text2: 'Застосунок дає можливість формувати звіти відповідно руху товарів, а також контролювати наявність, переобліки, оприбуткування та навіть продажі зі складу.',
                text3: 'KavАpp Delivery дозволяє логісту приймати замовлення від баристи, підтверджувати списання/інкасації та закупівлі на торгових точках. Також має вбудований месенджер для обміну текстовими повідомленнями та медіа-файлами.',
                list1: [
                    {text: 'У відповідності до бізнес-критеріїв розроблено структуру мобільного застосунку.'},
                    {text: 'Створено дизайн та функціонал проєкту відповідно до вимог замовника.'},
                    {text: 'Розроблено, протестовано та опубліковано мобільну програму.'},
                ],
                list2: [
                    {text: 'Приєднано мобільний застосунок до АРІ, відповідно до методології REST АРІ.'},
                    {text: 'Запроваджено безпеку та шифрування конфіденційних даних.'},
                ],
                text_accent: 'Технології, які використовували: Flutter, Dart, BLoC, Dio, Hive, Drift, Freezed, REST АРІ, Firebase, GitHub, Figma, Android Studio, Xсode.'
            },
            admin: {
                text1: 'Панель адміністратора, створена як вебсайт та веб-застосунок, призначена для керування мобільними програмами Kavapp Seller та Kavapp Delivery, а також для полегшення роботи адміністратора кав\'ярні.',
                text2: 'Kavapp admin дозволяє легко змінити та доповнити всю інформацію, яка стосується меню кафе, працювати з персоналом (розрахунок заробітної плати та авансів, нарахування штрафів, премій, тощо), функціонувати зі складом, створювати та керувати системою знижок та акціями у кафе, а також бачити всі необхідні графіки та звіти у режимі реального часу.',
                text3: 'Сайт, також, має мобільну версію, через яку зручно мати доступ до всіх можливостей панелі адміністратора з мобільного телефону.',
                list1: [
                    {text: 'Створено структуру проєкту.'},
                    {text: 'Розроблено дизайн та функціонал проєкту відповідно до бюджету та умов замовника.'},
                    {text: 'Створено та впроваджено АРІ.'},
                ],
                list2: [
                    {text: 'Розроблено та протестовано Web-сайт.'},
                    {text: 'Інтегровано податкову звітність в проєкт. '},
                    {text: 'Запроваджено безпеку та шифрування конфіденційних даних.'}
                ],
                text_accent: 'Технології, які використовували: React, Redux, JavaScript, React Router, Redux Form, MUI, Axios, Sass, webpack, PHP, MySQL.'
            },
        }
    },
    cm: {
        text_accent: 'Мобільний застосунок розроблений для клієнтів мережі кав\'ярень Coffee Mobile.',
        text: 'Метою програми є зручна та доступна система знижок для клієнтів мережі кав\'ярень. Застосунок має індивідуальний дизайн, зручний інтерфейс та великий функціонал.',
        page: {
            list: [
                {text: 'Розроблено структуру та дизайн системи Coffee Mobile (мобільного застосунку та адмін-панелі).'},
                {text: 'Розроблено та впроваджено глобальну базу даних та API, використовуючи PHP та MySQL.'},
                {text: 'Підключено застосунок до програми обліку клієнтів у своїх точках продажу.'},
            ],
            list2: [
                {text: 'Розроблено мобільну програму та адмін-панель з урахуванням усіх вимог замовника.'},
                {text: 'Протестовано програму та панель адміністратора.'},
                {text: 'Опубліковано програму в App Store та Play Market.'},
            ],


            descriptionText: [
                {text: 'Мобільний застосунок орієнтований на клієнтів мережі кав’ярень Coffee Mobile та має індивідуальний дизайн, зручний інтерфейс і широкий функціонал. Основна мета програми – зручна та доступна система знижок для клієнтів кав’ярень.'},
                {text: 'Користувачі програми, крім використання системи знижок, можуть використовувати щотижневі та регулярні завдання, які нагороджуються додатковими бонусами, ділитися бонусами з іншими користувачами, переглядати оцінки користувачів, підвищувати рейтинг, здійснювати покупки в онлайн-магазині, що також є в застосунку.'},
            ],

            descriptionList1: [
                {text: 'Розроблено структуру та дизайн програми.'},
                {text: 'Підключено застосунок до програми обліку клієнтів на точках продажу.'},
                {text: 'Розроблено програму з урахуванням усіх вимог замовника (використовуючи Dart, Flutter, Hive).'},
            ],
            descriptionList2: [
                {text: 'Під’єднано до API для швидкого оновлення завдань та зберігання статистики.'},
                {text: 'Протестовано та виправлено помилки застосунку.'},
                {text: 'Опубліковано програму в App Store та Play Market.'},
            ],
            description_accent: 'Технології, які використовували:  Flutter, Dart, Hive, REST АРІ, Firebase, Google Maps, Git, GitHub, Analytics, Figma, Android Studio, Xcode.',


            cm_admin: {
                text1: 'Для керування мобільним застосунком Coffee Mobile було розроблено адмін-панель, яка дозволяє зручно управляти всіма функціями програми та технічною підтримкою. ',
                text2: 'Завдяки Coffee Mobile Admin відбувається керування системою знижок кавошопів: дисконтною системою, нарахуванням та списанням бонусів, переглядом знижок.',
                text3: 'Управління інтернет-магазином, який вбудований в мобільний застосунок,  відбувається через цю адмін-панель, що дозволяє здійснювати контроль за позиціями та замовленнями.',
                list1: [
                    {text: 'Розроблено структуру та дизайн адмін-панелі.'},
                    {text: 'Розроблено та впроваджено глобальну базу даних та API для швидкого оновлення завдань та зберігання статистики (використовуючи PHP, MySQL).'},
                ],
                list2: [
                    {text: 'Розроблено адмін-панель для керування програмою (з використанням React, JavaScript, CSS та HTML).'},
                    {text: 'Протестовано та виправлено помилки панелі адміністратора.'},
                ],
                text_accent: 'Що використовували – технології: React, JavaScript, Redux, Redux Form , MUI, Axios, Sass, CSS, HTML, PHP, MySQL.'
            }
        }

    },
    tests: {
        title: 'Тестування для державних службовців',
        text_accent: 'Мобільні програми для iOS та Android, які створені для швидкої та ефективної підготовки до сертифікації, або вступу до державних установ.',
        text_accent2: 'Особливістю даних тестів є можливість вивчення матеріалу за методом Лейтнера.',
        page: {
            list1: [
                {text: 'Досліджено область подібних програм і сформовано індивідуальні особливості власної розробки.'},
                {text: 'Створено структуру та дизайн застосунків.'},
                {text: 'Сформовано та впроваджено глобальну базу даних, в тому числі API для завантаження тестів та зберігання статистики.'},
            ],
            list2: [
                {text: 'Дотримання усіх вимог замовника в розробці проєкта.'},
                {text: 'Протестовано та виправлено помилки програм.'},
                {text: 'Опубліковано програми в App Store та Play Market.'},
            ],
            descriptionText: [
                {text: 'Мобільні застосунки, що підтримуються iOS та Android, створені, для швидкої та ефективної підготовки до сертифікації або вступу до державних установ.'},
                {text: 'Головна перевага наших тестів – вивчення матеріалу методом Лейтнера, особливість якого полягає в періодичному повторенні матеріалу, що вивчається. Питання, які даються учневі важче, повторюються частіше, ніж ті, які легше запам\'ятати.'},
                {text: 'Було також запроваджено анонімний збір результатів, для аналізу та порівняння своєї ефективності з іншими учасниками тестування.'},
            ],
            accent_text: 'Технології, які використовували: Swift, Kotlin, SQLite, Android Studio, Xcode, PHP, MySQL.'
        }
    }
,   meta: {
        title:
            "Портфоліо компанії СофтВарс: мобільна розробка, веб та вебдизайн",
        description: "Портфоліо компанії СофтВарс: розробка мобільних застосунків, створення сайтів та розробка вебдизайну для ваших проєктів. Роботи компанії СофтВарс"
    },
}

export default portfolio