const design = {
    design: "Design",
    work: "We are at work",
    stage_work: "Stages of work",
    inWorkWeUseItems: [
        "We use options and components in our work",
        "We create a prototype of the project for the convenience of working with it for both designers and developers",
        "We test the prototype for convenience and accessibility for the user",
        "Using color and text styles",
        "We are creating a UI-Kit",
        "Using Google's Material Design and Human Interface guidelines"
    ],
    tools: "Tools",
    we_create: "The products we create",
};

export default design;
