const web = {
  kavappAdmin:
    "Kavapp Admin is a website designed for managing and controlling the entire Kavapp system.\n" +
      "\n" +
      "Through this platform, administrators can do coffee shop settings, create menus, add ingredients and employees, as well as view detailed reporting on business operations.",
  coffeeMobileAdmin:
    "The administrator panel has been developed to manage the Coffee Mobile mobile application.\n" +
      "\n" +
      "The admin panel provides the capability to control every aspect of settings, manage technical support, and oversee positions and orders within the in-app internet store.",
  cars: "The website for the car buyout company has been developed taking into account the needs of the target audience.\n" +
      "\n" +
      "The primary goal of the website is to provide users with information about the services, available cooperation options, an easy and convenient method for feedback, and the ability to provide a pre-assessment of the customer's vehicle.",
  kavappSite:
    "The Kavapp website serves as a showcase to present the capabilities of the Kavapp system to potential users.\n" +
      "\n" +
      "It provides detailed information about the system's features and benefits, demonstrating its value and functionality. If website visitors are interested in the system, they can easily register and start using Kavapp's services.",
  siteSmShop:
      "For the Coffee Mobile coffee shop chain, an online store has been developed and integrated with the company's previously developed website.\n" +
      "\n" +
      "This full-featured online store, which includes a product catalog and shopping cart, allows customers to browse and order products directly on the website without the requirement of creating a personal customer account.",

};

export default web;
