const applications = {
    cars: {
        info: {
            text1: 'The website has been developed for a company specializing in car buyouts, serving as a personal channel for finding clients',
            text2: 'The website\'s functionality has been designed with consideration for the specific nature of the company\'s business. Clients can not only explore the full range of services available in their region and the terms of collaboration but can also perform a preliminary express assessment and promptly receive information about the cost and details of selling their car.',
            text3: 'For convenience and effective communication with clients, the website offers the option to receive quick notifications via messaging apps and email about new requests from clients.',
        },
        technologiesWeUsed:
            "Technologies used: React, Redux, JavaScript, TypeScript, React Router, Emotion, Formik, i18next.",
        whatWeDidTitle: "Implemented:",
        whatWeDidItems: [
            "The website's structure was carefully planned.",
            "The design and functionality were created within the client's budget.",
            "Receiving notifications about new user requests through messaging was set up.",
            "Testing was conducted, and all identified bugs were fixed.",
            "The domain was configured, and the website was hosted on a web server.",
        ],
    },
    drivingRules: {
        info: {
            text1: 'The application is designed to prepare for the theoretical driving exam.\n' +
                '\n' +
                'Driving Rules allows to train with three testing methods:',
            arrayText: [
                '- Exam mode (corresponds to the official algorithm)',
                '- Standard method',
                '- Leitner method'
            ],
            text2: 'The advantage of the last methodology lies in the possibility of interval repetition of questions that are more difficult to learn until the material is fully mastered.',
            text3: 'Additionally, for user convenience, the application offers: detailed progress statistics, the ability to search for information by words or phrases, flexible test settings and notifications reminding users of new learning stages, the option to choose the application\'s color palette, customer support, and much more.\n' +
                '\n' +
                'The application is available to Android and iOS users.',
        },
        technologiesWeUsed:
            "Technologies used: Flutter, Dart, Bloc (Cubit), Firebase, Rest API, Drift, Freezed, Dio, Hive, Android Studio, XCode, Figma.",
        whatWeDidTitle: "Implemented:",
        whatWeDidItems: [
            "The structure, functionality, and design of the application have been created in accordance with the client's preferences.",
            "An API has been developed for rapid content updating and statistics storage.",
            "Integration with the admin panel of the support service has been configured.",
            "In-app purchases have been implemented on the App Store and Play Market.",
            "The application has been tested and bugs have been fixed.",
            "The program has been published on the App Store and Play Market.",
        ],
    },
    kavappSeller: {
        info: {
            text1: 'The application is designed for Android, iOS, and Windows with the goal of streamlining and, at the same time, monitoring the work of coffee shop employees.',
            text2: 'Using the program, an employee can open a work shift, record sales, orders, and ingredient usage, confirm inventory arrival, close a shift, and so on. Seller automatically loads menu items, prices, employee data, products, or ingredients from the server. Additionally, Kavapp Seller is integrated with the tax system and can fiscalize receipts if needed.',
            text3: 'The program has a built-in messenger for quick interaction with other parts of the Kavapp system, including Kavapp Delivery and Kavapp Admin. It also includes notifications for actions which require an administrator`s or a logistician`s confirmation. For example, when a barista places an order or writes off products, notifications about the abovementioned actions are sent to the logistician (to Kavapp Delivery).\n' +
                '\n' +
                'The application can function autonomously and synchronize data when connected to the internet.',
        },
        technologiesWeUsed:
            "Technologies used:  Flutter, Dart, BLoC, Dio, Hive, Drift, Freezed, Firebase, GitHub, Figma, Android Studio, Xсode, REST АРІ.",
        whatWeDidTitle: "Implemented:",
        whatWeDidItems: [
            "The structure of the mobile application project has been carefully planned.",
            "Design and functionality have been created within the client's budget.",
            "The mobile program has been developed, tested, and published on the App Store, Play Market, and Microsoft Store.",
            "The mobile application has been connected to APIs following the REST API methodology.",
            "The ability to connect receipt printers with Wi-Fi, USB, and Bluetooth interfaces has been implemented.",
            "Сonfidential data security and encryption have been introduced.",
        ],
    },
    kavappDelivery: {
        info: {
            text1: 'The mobile program, available for Android and iOS, is designed to monitor the movement of goods from the warehouse to the coffee shop and all aspects related to warehouse logistics.',
            text2: 'The application allows users to form reports in correspondence to the movement of goods and also gives a possibility to control the availability of goods, inventories, arrivals, and sales from the warehouse.',
            text3: 'Kavapp Delivery allows logisticians to receive orders from baristas, confirm write-offs/collections, and make purchases at the points of sale. It also includes a built-in messenger for exchanging text messages and media files.',
        },
        technologiesWeUsed:
            "Technologies used: Flutter, Dart, BLoC, Dio, Hive, Drift, Freezed, REST АРІ, Firebase, GitHub, Figma, Android Studio, Xсode.",
        whatWeDidTitle: "Implemented:",
        whatWeDidItems: [
            "In accordance with the business criteria, the structure of the mobile application has been developed.",
            "The design and functionality of the project have been created in line with the client's requirements.",
            "The mobile program has been developed, tested, and released.",
            "The mobile application has been integrated with APIs following the REST API methodology.",
            "Security and encryption of confidential data have been implemented.",
        ],
    },
    testingForCivilServants: {
        info: {
            text1: 'Mobile applications, compatible with iOS and Android, have been developed for quick and effective preparation for the test on legislation knowledge required for employment in government institutions and law enforcement agencies.',
            text2: 'The main advantage of these tests lies in the Leitner method of learning, characterized by systematic repetition of the material which is difficult to memorize. ',
            text3: 'In addition to up-to-date test questions, the program provides access to statistics of test results and explanations. Anonymous collection of results has been introduced for analysis and comparison of one\'s performance with other test participants.',
        },
        technologiesWeUsed:
            "Technologies used: Flutter, Dart, Bloc (Cubit), Firebase, Rest API, Drift, Freezed, Dio, Hive, Android Studio, XCode, Figma.",
        whatWeDidTitle: "Implemented:",
        whatWeDidItems: [
            "The area of similar programs was researched, and individual features for the own development were defined.",
            "The structure and design of the applications were created.",
            "A global database, including an API for test uploads and statistics storage, was developed and implemented.",
            "Meeting all the client`s requirements in project development.",
            "The programs were tested and bugs were fixed.",
            "The programs were published on the App Store and Play Market.",
        ],
    },
    kavappAdmin: {
        info: {
            text1: 'Kavapp Admin is an administrator web panel designed for coffee shops management.',
            text2: 'It enables the forming of menus, ingredients, and products, and gives a possibility to calculate staff salaries. It includes an embedded analytics system for generating reports on sales, and inventory management, and offers tools for managing promotions and discounts. All of these features are designed to simplify the work of a coffee shop administrator.',
            text3: 'It facilitates the interaction of Kavapp Seller and Kavapp Delivery for complex business management. The website is also optimized for mobile devices, making it easy to manage all administrative panel functions via smartphones.',
        },
        technologiesWeUsed:
            "Technologies used: React, Redux, JavaScript, React Router, Redux Form, MUI, Axios, Sass, webpack, PHP, MySQL.",
        whatWeDidTitle: "Implemented:",
        whatWeDidItems: [
            "The project structure has been defined.",
            "The design and functionality of the admin panel have been developed within the budget and according to the client's requirements.",
            "API has been created and implemented.",
            "The website has been developed and tested.",
            "Tax reporting has been integrated into the project.",
            "Security and encryption of confidential data have been implemented.",
        ],
    },
    coffeeMobile: {
        info: {
            text1: 'The mobile application is oriented to customers of the Coffee Mobile coffee shop chain. The main goal of the program is to provide a convenient and accessible discount system for customers.',
            text2: 'Users of the app, in addition to using the discount and cashback system with each purchase, can also complete weekly and regular tasks to earn additional bonuses. They can give bonuses to other users, transfer a change to their bonus account, change the application\'s color palette, view purchase statistics, and much more.',
            text3: 'The application is compatible with Android and iOS.',
        },
        technologiesWeUsed:
            "Technologies used: Flutter, Dart, Hive, REST АРІ, Firebase, Google Maps, Git, GitHub, Analytics, Figma, Android Studio, Xcode.",
        whatWeDidTitle: "Implemented:",
        whatWeDidItems: [
            "The structure and design of the project have been created.",
            "The application has been connected to the customer account management system at the point of sale.",
            "The program has been developed meeting all client requirements (using Dart, Flutter, Hive).",
            "It has been connected to an API for quick task updates and statistics storage.",
            "The application has been tested and bugs have been fixed.",
            "The program has been published on the App Store and Play Market.",
        ],
    },
    coffeeMobileAdmin: {
        info: {
            text1: 'The admin panel has been developed for managing the Coffee Mobile mobile application. It allows to set up the application and edit the information within the app without its updating.',
            text2: 'With Coffee Mobile Admin, there is an opportunity to edit banners, modify temporary tasks, add or remove products in the in-app store, and respond to user messages via the customer support messenger.',
            text3: 'The admin panel also keeps information about points of sale and provides access to sales reports.'
        },
        technologiesWeUsed:
            "Technologies used: React, JavaScript, Redux, Redux Form, MUI, Axios, Sass, CSS, HTML, PHP, MySQL, Figma.",
        whatWeDidTitle: "Implemented:",
        whatWeDidItems: [
            "The project structure and design were created.",
            "A global database and API were developed and implemented for rapid task update and statistics storage (using PHP and MySQL).",
            "The functionality of the admin panel for the program management was developed in correspondence to the design (using React, JavaScript, CSS, and HTML).",
            "The admin panel was tested and bugs were fixed.",
        ],
    },
    kavappSite: {
        info: {
            text1: 'For the Kavapp coffee shop automation system, a website has been developed as a platform for engaging potential clients and interacting with existing ones.',
            text2: 'Website users can get acquainted with all the products of the system, including the pricing for the offered services. For customer communication, an easy and convenient method of feedback has been implemented, with the option to choose the communication platform.',
            text3: 'Additionally, the website has an option for immediate registration in the Kavapp system and logging into a personal account.'
        },
        technologiesWeUsed:
            "Technologies used: React, JavaScript, React Router DOM, CSS, Emotion, Axios",
        whatWeDidTitle: "Implemented:",
        whatWeDidItems: [
            "A prototype and final design have been developed.",
            "The functionality has been carefully thought out and created on the base of the client's requirements.",
            "Testing has been conducted, and bugs have been fixed.",
            "The domain has been configured, and the website has been hosted on a web server.",
        ],
    },
    cmShopSite: {
        info: {
            text1: 'For the Coffee Mobile brand (abbreviated as CM), a convenient and easy-to-use online store was developed with the purpose of online trading automation.',
            text2: 'The website contains functionality that encompasses the essential business processes of commerce: a product catalog (categorized into groups and subgroups, with the ability to add new items and edit existing ones), order processing, choosing payment and delivery methods, order tracking, and inventory management.',
            text3: 'A unique feature of the website is the ability to select wholesale purchase amounts, with pricing policies that change depending on the selected quantity of goods (the larger the order amount, the lower the cost per item).\n' +
                '\n' +
                'Integration of payment systems has been configured, and ways for the personal data protection have been thought out.'
        },
        technologiesWeUsed:
            "Technologies used: Opencart, React, JavaScript, PHP, MySQL, Figma",
        whatWeDidTitle: "Implemented:",
        whatWeDidItems: [
            "Website architecture has been developed.",
            "The design and color scheme have been created, taking into account all the client`s requirements.",
            "The online store has been integrated into the corporate website.",
            "The website has been adapted for both mobile and desktop versions.",
            "Integration with payment systems has been implemented.",
            "Automatic product export for Google Ads has been integrated.",
            'Tested and hosted.'
        ],

    },
    button: 'Go to the project',

};

export default applications;
