import {configureStore} from '@reduxjs/toolkit';
import applicationSlice from './applicationSlice';

const store = configureStore({
    reducer: {
        application: applicationSlice,
    },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
