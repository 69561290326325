const home = {
    bannerText:
        "We develop modern programs that please both our customers and users",
    number_users: "satisfied users",
    years: "years on the market",
    number_projects: "successfully realized projects",
    service: {
        mobile: {
            title: "Mobile applications",
            text1:
                "Nowadays, a mobile application is a necessity for users: clients, employees, or business partners. It is a popular tool for attracting new and retaining regular users, improving your company's rating, and promoting your brand.",
            text2:
                "SoftWars provides high-tech services in mobile application development that will raise your business to a new level. Our developers are qualified professionals who will create reliable code, a user-friendly interface, and a pleasant design for your mobile application.",
            text3:
                "Our company has a long-term experience in developing applications that use geolocation, camera, Bluetooth, notifications, media files, in-app purchases, APIs, and other functionality.",
            textAccent:
                "In program development, we use: Flutter, Dart, Xcode, Android Studio",
        },
        web: {
            title: "Web",
            text1:
                "Rapid digitalization creates conditions where every business, in order to operate successfully, must have a website to maximize profits and enhance customer loyalty.",
            text2:
                "Web development offers a wide range of possibilities, including complex web applications, online stores, static websites, and APIs for mobile applications. Depending on the website's functionality, developers face various tasks.",
            text3:
                "SoftWars successfully develops projects of any complexity, both on the client side and server side and has wide experience in web development. The results of our work are projects that meet the highest standards, and clients, satisfied with them.",
            textAccent:
                "In web development, we use: React, JavaScript, CSS, HTML, Node.js, PHP, Laravel, MySQL",
        },
        design: {
            title: "Design",
            text1:
                "The project design is primarily assessed by clients, before its functionality. Pleasant design and a user-friendly interface are the most important characteristics of a mobile or web application.",
            text2:
                "SoftWars has extensive experience in the successful use of the Material Design system, actual color palettes, and well-selected typography; developing and creating individual logos and their animations.",
            text3:
                "Our specialists analyze user scripts, and carefully plan the logic of transitioning from one page to another, allowing users to achieve their goals when using the product in the simplest and most convenient way possible.",
            text4:
                "We incorporate the latest design trends and adhere to UI/UX principles, which is why we guarantee the high quality of our work done.",
            textAccent:
                "For design development, we use: Figma, Adobe Illustrator, Adobe Photoshop, Adobe After Effects",
        },
        serviceBtnTitle: "View",
    },
    meta: {
        title:
            "SoftWars - web and mobile app development, and web design",
        description: "SoftWars company - development of modern websites, development of mobile applications, and web design development for your project. We develop modern programs that please both our customers and users"
    },

    portfolio: {
        viewMorePortfolioItemsBtn: "View more",
    },
};

export default home;
