const words = {
    discuss_the_project: 'Обговорити проєкт',
    learn_more: 'Дізнатися більше',
    view_all: 'Переглянути всі',
    view_project: 'Переглянути проєкт',
    or: 'або',
    send: 'Надіслати',
    name: 'Ім\'я',
    email: 'Електронна пошта',
    country: 'Країна',
    phone_number: 'Номер телефону',
    form_textarea: 'Розкажіть про свою ідею чи проєкт',
    what_we_did: 'Що ми зробили:',
    send_form_success: 'Ваше повідомлення надіслано',
    send_form_error: 'Виникла помилка, спробуйте пізніше',
    contacts: 'Контакти',
    feedback: 'Зворотній зв’язок',
    CM_shop_long: 'Інтернет-магазин \n Coffee Mobile',
    CM_shop_short: 'Інтернет-магазин CM',
    kavapp_name: 'Вебсайт Kavapp',
    test_name: 'Тести для держслужбовців',
    meta: {
        title:
            "Контакти компанії СофтВарс. СофтВарс - залишити заявку на прорахунок",
        description: "Контакти компанії СофтВарс. Прорахувати вартість розробки сайту чи мобільного застосунку. Зв'язатись з компанією СофтВарс"
    },
}

export default words