const aboutUs = {
    historyOfDevelopmentText: {
        text1: "SoftWars - стрімко зростаюча компанія з розробки програмного забезпечення, з більш ніж з 6-річним досвідом на ринку.",
        text2: "Впродовж багатьох років ми накопичили досвід у сферах розробки програмного забезпечення, вебдизайну та маркетингу, опираючись на інновації, якість та відданність справі. Що дає нам можливість не просто розробити ваш задум, а спираючись на досвід допомогти вам побудувати дійсно успішний бізнес.",
        text3: "Наша мета - професійна розробка вашого проєкту. Ми гарантуємо супровід до та після релізу, забезпечуючи клієнтів можливістю отримати готовий, протестований продукт.",
        text4: "Досвід реалізації проєктів різних масштабів та складності дозволяє команді SoftWars вирішувати завдання різних типів. Напишіть нам для обговорення вашої ідеї, на якому б етапі вона не була: ми допоможемо розкрити як тільки \"народжену\" ідею так і покращити вже сформований продукт."
    },
    qualificationText:
        {
            text1: "Команда SoftWars спеціалізується на створені мобільних застосунків, Front-end і Backend-розробці, web-дизайні та маркетингу.",
            text2: "Фахівці команди рівнів Junior, Middle та Senior в роботі дотримуються принципів ООР та SOLID, працюють з JIRA, Trello, ClickUp та Confluence. У відповідності до напрямку розробки програмного забезпечення мають стек:",
            text3: "<span style='color:#28E0D1 '>Mobile:</span> FIutter, Dart, Bloc, Riverpod, Dio, Hive, Freezed, Restful API, JSON, Firebase, Git, Bitbucket, GitLab.",
            text4: "<span style='color:#28E0D1 '>Front-end:</span> React, Redux, JavaScript, TypeScript, Mobx, PropTypes, Axios, HTML, CSS, SCSS, BEM, REST API, Git, Bitbucket, npm, webpack.",
            text5: "<span style='color:#28E0D1 '>Backend:</span> PHP, Laravel Framework, MySQL, PostgreSQL, MongoDB, Node.js, Next.js, RESTful API, Unittest, Postman, Google API, Firebase, Facebook API, Git, Docker, Compose.",
            text6: "<span style='color:#28E0D1 '>Стек вебдизайну:</span> Figma, Adobe Illustrator, Adobe Photoshop, Adobe After Effects та Autodesk Maya. Використання останніх тенденції дизайну та дотримання принципів UI/UX, гарантує якість виконання дизайнерської роботи.",
            text7: "Великий досвід наших фахівців у розробці власних продуктів та проєктів на замовлення (в тому числі, з нуля), дають можливість співпраці з нашою командою за моделлю аутстаф та аутсорс.",
        },
    title: "Історія розвитку",
    qualification: "Кваліфікація",
    about_us: 'Про нас',
    view: "Переглянути послуги",
    meta: {
        title:
            "СофтВарс - ваш надійний партнер у світі веб та мобільної розробки",
        description: "СофтВарс має великий досвід з розробки сайтів та розробки мобільних застосунків. Шукаєш компанію на аутсорс? СофтВарс - найкращий вибір"
    },
};

export default aboutUs;
