const aboutUs = {
    historyOfDevelopmentText: {
        text1: "SoftWars is a rapidly growing software development company with over 6 years of experience in the industry.",
        text2: "Over the years, we have accumulated expertise in software development, web design, and marketing, relying on innovation, quality, and dedication to our deal. This enables us not only to bring your ideas to life but also to help you build a truly successful business based on our experience.",
        text3: "Our goal is to provide professional development for your project. We guarantee support before and after release, ensuring that clients receive a ready-to-use, thoroughly tested product.",
        text4: "Our experience in implementing projects of various scales and complexities lets the SoftWars team copy with tasks of different types. Contact us to discuss your idea, no matter at what stage it is: we will help both to bring a newly conceived idea to life and improve an already formed product."
    },
    qualificationText:
        {
            text1: "The SoftWars team specializes in mobile applications development, front-end and back-end development, web design, and marketing.",
            text2: "The specialists of our team, spanning Junior, Middle, and Senior levels, stick to OOP and SOLID principles. They work with JIRA, Trello, ClickUp, and Confluence. Depending on the direction of software development, they possess the following technology stacks:",
            text3: "<span style='color:#28E0D1 '>Mobile:</span> FIutter, Dart, Bloc, Riverpod, Dio, Hive, Freezed, Restful API, JSON, Firebase, Git, Bitbucket, GitLab.",
            text4: "<span style='color:#28E0D1 '>Front-end:</span> React, Redux, JavaScript, TypeScript, Mobx, PropTypes, Axios, HTML, CSS, SCSS, BEM, REST API, Git, Bitbucket, npm, webpack.",
            text5: "<span style='color:#28E0D1 '>Backend:</span> PHP, Laravel Framework, MySQL, PostgreSQL, MongoDB, Node.js, Next.js, RESTful API, Unittest, Postman, Google API, Firebase, Facebook API, Git, Docker, Composer.",
            text6: "<span style='color:#28E0D1 '> The web design specialists' stack includes:</span> Figma, Adobe Illustrator, Adobe Photoshop, Adobe After Effects, and Autodesk Maya. Utilizing the latest design trends and adhering to UI/UX principles ensures the quality of our design work.",
            text7: "The extensive experience of our professionals in developing custom products and projects (including from scratch) enables collaboration with our team through outstaffing and outsourcing models.",
        },
    title: "History of development",
    qualification: "Qualification"
    , about_us: 'About us',
    view: "View services",
    meta: {
        title:
            "SoftWars is your trusted partner in the world of web and mobile development",
        description: "SoftWars has extensive experience in website development and mobile application development. Looking for an outsourcing company? SoftWars is the best choice"
    },
};

export default aboutUs;
