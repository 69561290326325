import i18n from 'i18next';
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import en from "../locales/en/translation";
import uk from "../locales/uk/translation";
import {languagesType} from "../types/types";

const resources: Record<languagesType, any> = {en, uk};

const defaultLanguage: languagesType = 'en'
const supportedLngs: languagesType[] = ['en', 'uk']

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        react: {useSuspense: false},
        fallbackLng: defaultLanguage,
        supportedLngs: supportedLngs,
        detection: {
            order: ['localStorage', 'navigator'],
            caches: ['localStorage']
        },
        interpolation: {
            escapeValue: false
        },
        debug: false
    });

export default i18n