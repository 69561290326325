const portfolio = {
    kavapp: {
        list: [
            {text: 'Personal account (Admin) - for the administrator.'},
            {text: 'Sales program (Seller) - for the barista.'},
            {text: 'Warehouse and delivery (Delivery) - for the logistician.'}
        ],
        text_accent: 'Kavapp is a multifunctional and comprehensive system for automating coffee shops, mini-cafes, and car cafes.',
        text: 'The system is divided into 3 categories:',

        page: {
            list: [
                {text: 'The global structure of the project, developed according to business objectives.'},
                {text: 'The design and functionality of the project were determined in accordance with the budget and customer conditions.'},
                {text: 'Developed and implemented API for admin panel and mobile applications (using PHP, MySQL).'},
                {text: 'Created mobile applications Kavapp Seller, Kavapp Delivery (using Flutter, Dart), and Kavapp admin panel (React).'}
            ],
            list2: [
                {text: 'Security and encryption of sensitive data are implemented.'},
                {text: 'Connected payment system (using Liqpay).'},
                {text: 'The opportunity realized of connecting receipt printers equipped with Wi-Fi, USB, and Bluetooth connection interfaces has been implemented. '},
                {text: 'Access has been granted to financial reporting developed by the standards of the Tax System of Ukraine is provided.'}
            ],
            seller: {
                text1: 'A mobile application for Android, iOS, and Windows, the purpose of which is to facilitate and, at the same time, control the work of the barista.',
                text2: 'With the help of the program, an employee opens a work shift, registers sales, orders, and write-offs of ingredients, confirms accruals, closes the shift, etc. The program automatically downloads from the server menus, prices, directories of workers, goods, and ingredients, and also downloads to the server a list of fiscal receipts with sold goods and other necessary information.',
                text3: 'The program has an option for instant messaging and is interconnected with the mobile application Kavapp Delivery. So, when a barista makes an order or write-off, the logistician in Kavapp Delivery receives notifications about the above actions.',
                text4: 'The application can work offline and synchronize when connected to the Internet.',
                list1: [
                    {text: 'The structure of the mobile application project has been developed.'},
                    {text: 'The design and functionality of the project were created in accordance with the customer\'s budget.'},
                    {text: 'Developed, tested and published a mobile application in the App Store, Play Market, and Microsoft Store.'},
                ],
                list2: [
                    {text: 'A mobile application has been connected to API by REST API methodology.'},
                    {text: 'The possibility of connecting receipt printers equipped with Wi-Fi, USB, and Bluetooth connection interfaces has been implemented.'},
                    {text: 'Security and encryption of confidential data are implemented.'}
                ],
                text_accent: 'Technologies used: Flutter, Dart, BLoC, Dio, Hive, Drift, Freezed, Firebase, GitHub, Figma, Android Studio, Xсode, REST API.'
            },
            delivery: {
                text1: 'The mobile application, for Android and iOS, is designed to control the movement of goods from the warehouse to the coffee shop, as well as everything related to warehouse logistics.',
                text2: 'The application makes it possible to generate reports according to the movement of goods, as well as monitor availability, re-accounting, posting, and even sales from the warehouse.',
                text3: 'Kavapp Delivery allows the logistician to accept orders from baristas, and confirm write-off/cash collection and purchase of goods at retail outlets. It also has a built-in messenger for exchanging text messages and media files. ',
                list1: [
                    {text: 'In accordance with the business criteria, the structure of the mobile application was developed.'},
                    {text: 'The design and functionality of the project were created by the customer\'s requirements.'},
                    {text: 'Developed, tested and published a mobile application.'},
                ],
                list2: [
                    {text: 'A mobile application has been connected to API, by REST API methodology.'},
                    {text: 'Security and encryption of confidential data were introduced. '},
                ],
                text_accent: 'Technologies used: Flutter, Dart, BLoC, Dio, Hive, Drift, Freezed, REST АРI, Firebase, GitHub, Figma, Android Studio, Xсode.'
            },
            admin: {
                text1: 'The admin panel, created as a website and a web application, is intended to manage the Kavapp Seller and Kavapp Delivery mobile applications, as well as to facilitate the work of the coffee shop administrator.',
                text2: 'Kavapp admin allows you to easily change and supplement all information related to the cafe menu, work with personnel (calculation of wages and advances, calculation of fines, bonuses, etc.), function with the warehouse, create and manage the system of discounts and promotions in the cafe, as well as see all the graphs and reports you need in real-time.',
                text3: 'The site also has a mobile version, through which it is convenient to have access to all the features of the administrator panel from a mobile phone.',
                list1: [
                    {text: 'The project structure has been created.'},
                    {text: 'The design and functionality of the project were developed by the budget and customer conditions.'},
                    {text: 'Created and implemented ARI.'},
                ],
                list2: [
                    {text: 'Developed and tested the website.'},
                    {text: 'Tax reporting is integrated into the project.'},
                    {text: 'Security and encryption of confidential data were implemented.'}
                ],
                text_accent: 'Technologies used: React, Redux, JavaScript, React Router, Redux Form, MUI, Axios, Sass, webpack, PHP, MySQL'
            }
        }
    },
    cm: {
        text_accent: 'The mobile application has been developed for customers of the Coffee Mobile network of coffee shops.',
        text: 'The purpose of the program is a convenient and affordable system of discounts for customers of the chain of coffee shops. The application has an individual design, a user-friendly interface, and extensive functionality.',
        page: {
            list: [
                {text: 'The structure and design of the Coffee Mobile system (mobile application and admin panel) were developed.'},
                {text: 'Developed and implemented a global database and API using PHP and MySQL.'},
                {text: 'Connected the application to the customer accounting program at their sales points.'},
            ],
            list2: [
                {text: 'A mobile application and admin panel were developed taking into account all the customer\'s requirements.'},
                {text: 'The program and admin panel have been tested.'},
                {text: 'The program was published in the App Store and Play Market. '},
            ],


            descriptionText: [
                {text: 'The mobile application is aimed at customers of the Coffee Mobile network of coffee shops and has an individual design, a convenient interface, and wide functionality. The main goal of the program is a convenient and affordable system of discounts for customers of coffee shops.'},
                {text: 'Users of the program, in addition to using the discount system, can use weekly and regular tasks that are awarded additional bonuses, share bonuses with other users, view user ratings, increase the rating, and make purchases in the online store, which is also in the application.'},
            ],
            descriptionList1: [
                {text: 'The structure and design of the program were developed.'},
                {text: 'Connected the application to the customer accounting program at their sales points.'},
                {text: 'The program was developed taking into account all the customer\'s requirements (using Dart, Flutter, and Hive).'},
            ],
            descriptionList2: [
                {text: 'Connected to an API for quick task updates and statistics storage.'},
                {text: 'Tested and fixed App bugs.'},
                {text: 'The program was published in the App Store and Play Market.'},
            ],
            description_accent: 'Technologies used: Flutter, Dart, Hive, REST АРI, Firebase, Google Maps, Git, GitHub, Analytics, Figma, Android Studio, Xcode.',

            cm_admin: {
                text1: 'To manage the Coffee Mobile application, an admin panel was developed, which allows you to conveniently manage all the functions of the application and technical support.',
                text2: 'Through Coffee Mobile Admin, the coffee shop discount system is managed: the discount system, the accrual and withdrawal of bonuses, and the review of discounts.',
                text3: 'Management of the online store, which is built into the mobile application, takes place through this admin panel, which allows you to control positions and orders.',
                list1: [
                    {text: 'Developed the structure and design of the admin panel.'},
                    {text: 'Developed and implemented a global database and API for quick task updates and statistics storage (using PHP, and MySQL).'},
                ],
                list2: [
                    {text: 'Developed an admin panel for program management (using React, JavaScript, CSS, and HTML).'},
                    {text: 'Tested and fixed admin panel bugs.'},
                ],
                text_accent: 'Technologies used: React, JavaScript, Redux, Redux Form, MUI, Axios, Sass, CSS, HTML, PHP, MySQL.'
            }
        }
    },
    tests: {
        title: 'Testing for civil servants',
        text_accent: 'Mobile applications for iOS and Android, which are created for quick and efficient preparation for certification or admission to government institutions.',
        text_accent2: 'A feature of these tests is the possibility of studying the material according to Leitner\'s method.',
        page: {
            list1: [
                {text: 'The area of similar programs was analyzed and individual features of own developments were formed.'},
                {text: 'Created the structure and design of applications.'},
                {text: 'A global database was created and implemented, including an API for downloading tests and storing statistics.'},
            ],
            list2: [
                {text: 'Compliance with all customer requirements in project development.'},
                {text: 'Tested and fixed program bugs.'},
                {text: 'Published applications in the App Store and Play Market. '},
            ],
            descriptionText: [
                {text: 'Mobile applications which are supported by iOS and Android is created for quick and efficient preparation for certification or admission to government institutions.'},
                {text: 'The main advantage of our tests is studying the material using the Leitner method, the feature of which is the periodic repetition of the material being studied. Questions that are more difficult for the student are repeated more often than those, that are easier to remember.'},
                {text: 'An anonymous collection of results was also introduced to analyze and compare one\'s performance with other test participants.'},
            ],
            accent_text: 'Technologies used: Swift, Kotlin, SQLite, Android Studio, Xcode, PHP, MySQL.'
        }
    },
    meta: {
        title:
            "SoftWars company portfolio: mobile app development, web and web design",
        description: "SoftWars company portfolio: development of mobile applications, creation of websites and development of web design for your projects. Works of the SoftWars company"
    },

}

export default portfolio