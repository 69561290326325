const design = {
    design: "Дизайн",
    work: "В роботі ми",
    stage_work: "Етапи роботи",
    inWorkWeUseItems: [
        "Використовуємо в роботі варіанти та компоненти",
        "Прототипуємо проєкт для зручності роботи з ним як дизайнерів, так і розробників",
        "Тестуємо прототип на зручність та доступність для користувача",
        "Використання стилів кольорів та тексту",
        "Створюємо UI-Kit",
        "Використання гайдлайнів Google Material Design та Human Interface Guidelines"
    ],
    tools: "Інструменти" ,
    we_create:"Продукти, що ми створюємо",
};

export default design;
