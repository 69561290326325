import React, {Suspense, useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom'
import {Provider} from "react-redux";
import store from './store'
import './utils/i18n'
import ScrollToTop from "./helpers/ScrollToTop";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const LazyLoadedComponent = React.lazy(() => import('./App'));


export default function RootApp() {

    return (
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <ScrollToTop/>
                    <Suspense fallback={<div></div>}>
                        <LazyLoadedComponent/>
                    </Suspense>
                </BrowserRouter>
            </Provider>
        </React.StrictMode>
    );
}

root.render(
    <RootApp/>
);
