const words = {
    discuss_the_project: 'Discuss the project',
    learn_more: 'Learn more',
    view_all: 'View all',
    view_project: 'View the project',
    or: 'or',
    send: 'Send',
    name: 'Name',
    email: 'Email',
    country: 'Country',
    phone_number: 'Phone number',
    form_textarea: 'Tell us about you idea or project',
    what_we_did: 'What we did:',
    send_form_success: 'Your message sent',
    send_form_error: 'An error occurred, please try again later',
    contacts: 'Contacts',
    feedback: 'Contact form',
    CM_shop_long: 'Coffee Mobile \n online store',
    CM_shop_short: 'СМ online store',
    kavapp_name: 'Kavapp website',
    test_name: 'Testing for civil servants',
    meta: {
        title:
            "Contacts of the SoftWars company. SoftWars - leave a request for miscalculation",
        description: "Contacts of the SoftWars company. Calculate the cost of developing a website or mobile application. Contact SoftWars"
    },
}

export default words