import navigation from "./modules/navigation";
import home from "./modules/home";
import words from "./modules/words";
import validation from "./modules/validation";
import portfolio from "./modules/portfolio";
import mobileApplication from "./modules/mobile_application";
import web from "./modules/web";
import applications from "./modules/applications";
import aboutUs from "./modules/about_us";
import design from "./modules/design";
import page_portfolio from "./modules/page_portfolio";

const translation = {
    translation: {
        navigation,
        home,
        words,
        validation,
        portfolio,
        mobileApplication,
        web,
        applications,
        aboutUs,
        design,
        page_portfolio
    },
};

export default translation;
