const mobileApplication = {
    kavappDelivery: {
        text1: "Kavapp Delivery is a mobile application developed for managing the logistics of goods from the warehouse to the point of sale.",
        text2: "The program is compatible with the iOS and Android operating systems.",
        text3: "This product is a part of a larger coffee shop automation system called Kavapp."
    },
    kavappSeller:
        {
            text1: "Kavapp Seller is a mobile application for automating the operations of coffee shops, designed for sales registration and employee work monitoring.",
            text2: "It is compatible with the Android, iOS, and Windows operating systems.",
            text3: "The application is a part of the coffee shop automation system called Kavapp."
        },
    coffeeMobile:
        {
            text1: "The mobile application is developed for the customers of the Coffee Mobile coffee shop chain.",
            text2: "The purpose of the program is to provide a convenient and user-friendly discount system for the company's customers. The application features a unique design, a user-friendly interface, and extensive functionality."
        },
    drivingRules:
        {
            text1: "The mobile application is developed for iOS and Android and is designed to facilitate the learning of traffic rules for the purpose of passing the theoretical driving exam.",
            text2: "Users have the opportunity to study traffic rules with the help of testing. To enhance information retention, the application incorporates spaced repetition of the material.",
        },
    testing:
        {
            text1: "Mobile applications for iOS and Android have been created to enable rapid and effective preparation for legal knowledge tests. These tests are often required for employment in government institutions and law enforcement agencies.",
            text2: "One notable feature of these tests is the opportunity to study the material using the Leitner method.",
        },
    detail: "More details",
    application: "Mobile applications"
};

export default mobileApplication;