const navigation = {
    main: 'Homepage',
    service: 'Services',
    portfolio: 'Portfolio',
    contacts: 'Contacts',
    kavapp: 'Kavapp',
    aboutUs: 'About us',
    cm: 'Coffee Mobile',
    tests: 'Testing for civil servants'
}

export default navigation