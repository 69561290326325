const applications = {
    cars: {
        info: {
            text1: 'Вебсайт розроблено для компанії, яка займається автовикупом, як особистий канал пошуку клієнтів.',
            text2: 'Функціонал сайту розроблено з урахуванням специфіки сфери діяльності компанії. Так, клієнт може не лише ознайомитись з повним переліком послуг в конкретному регіоні та умовами співпраці, але і здійснити попередню експрес оцінку та оперативно отримати інформацію про вартість та деталі викупу свого автомобіля.',
            text3: 'Для зручності та ефективної комунікації з клієнтами, реалізовано можливість швидкого отримання сповіщень в месенджери та на електронну пошту про новий запит від клієнта.',
        },
        technologiesWeUsed:
            "Використані технології:  React, Redux, JavaScript, TypeScript, React Router, Emotion, Formik, i18next.",
        whatWeDidTitle: "Реалізовано:",
        whatWeDidItems: [
            "Продумано структуру вебсайту.",
            "Створено дизайн та функціонал відповідно до бюджету замовника.",
            "Налаштовано отримання сповіщень через месенджери про нові запити від користувачів.",
            "Протестовано та виправлено помилки.",
            "Налаштувано домен та розміщено сайт на вебсервері.",
        ],
    },
    drivingRules: {
        info: {
            text1: 'Застосунок створено для підготовки до теоретичного іспиту з ПДР.\n' +
                '\n' +
                'Driving Rules дозволяє здійснювати підготовку трьома методами тестування:',
            arrayText: [
                '- Режим іспиту (відповідає офіційному алгоритму)',
                '- Стандартний метод',
                '- Метод Лейтнера'
            ],
            text2: 'Перевага останнього, в можливості інтервального повторення питань, які важче піддаються вивченю, до остаточного засвоєння матеріалу.',
            text3: 'Також, для зручності користувачів реалізовано: детальну статистику проходження, можливість пошуку інформації за словами чи фразами; гнучке налаштування проходження тестів та сповіщення, що нагадують про нові етапи навчання, опція вибору кольорової палітри застосунку, служба підтримки та багато іншого.\n' +
                '\n' +
                'Застосунок доступний користувачам Android та іOS.',
        },
        technologiesWeUsed:
            "Використані технології: Flutter, Dart, Bloc (Cubit), Firebase, Rest API, Drift, Freezed, Dio, Hive, Android Studio, XCode, Figma.\n",
        whatWeDidTitle: "Реалізовано:",
        whatWeDidItems: [
            "Створено структуру, функціонал та дизайн застосунку з урахуванням побажаннь замовника",
            "Розроблено API для швидкої актуалізації контенту та зберігання статистики",
            "Налаштовано інтеграцію з адмін-панелю служби підтримки",
            "Реалізовано вбудовані покупки в App Store та Play Market",
            "Протестовано та виправлено помилки застосунку",
            "Опубліковано програму в App Store та Play Market",
        ],
    },
    kavappSeller: {
        info: {
            text1: 'Застосунок для Android, iOS та Windows, мета якого – полегшити і, водночас, контролювати роботу працівників кав\'ярні.',
            text2: 'За допомогою програми, співробітник відкриває робочу зміну, реєструє продажі, замовлення та списання інгредієнтів, підтверджує оприбуткування товару, закриває зміну тощо. Seller автоматично завантажує з сервера меню, ціни, дані про працівника, товар чи інгредієнти. А також, Kavapp Seller інтегрований з податковою та за потреби може фіскалізувати продані чеки.',
            text3: 'Програма має вбудований месенджер, для швидкої взаємодії з іншими частинами системи Kavаpp Delivery та Kavapp Admin. Реалізовані сповіщення дій які потребують підтвердження адміністратора чи логіста. Наприклад, коли бариста здійснює замовлення, або списання – логісту в Kavаpp Delivery приходять сповіщення про вище вказані дії.\n' +
                '\n' +
                'Застосунок може працювати автономно та синхронізуватися при підключенні до інтернету.',
        },
        technologiesWeUsed:
            "Використані технології: Flutter, Dart, BLoC, Dio, Hive, Drift, Freezed, Firebase, GitHub, Figma, Android Studio, Xсode, REST АРІ.",
        whatWeDidTitle: "Реалізовано:",
        whatWeDidItems: [
            "Продумано структуру проєкту мобільного застосунку.",
            "Створено дизайн та функціонал проєкту відповідно до бюджету замовника.",
            "Розроблено, протестовано та опубліковано мобільну програму в App Store, Play Market та Microsoft Store.",
            "Приєднано мобільний застосунок до АРІ, відповідно до методології REST АРІ.",
            "Реалізовано можливість під’єднання принтерів чеків, які оснащені інтерфейсами підключення - Wi-Fi, USB та Bluetooth.",
            "Запроваджено безпеку та шифрування конфіденційних даних",
        ],
    },
    kavappDelivery: {
        info: {
            text1: 'Мобільна програма, для Android та iOS, призначена для контролю переміщення товарів від складу до кав’ярні, а також усього, що стосується складської логістики.',
            text2: 'Застосунок дає можливість формувати звіти відповідно руху товарів, а також контролювати наявність, переобліки, оприбуткування та продажі зі складу.',
            text3: 'Kavаpp Delivery дозволяє логісту приймати замовлення від баристи, підтверджувати списання/інкасації та закупівлі на торгових точках. Також має вбудований месенджер для обміну текстовими повідомленнями та медіа-файлами.',
        },
        technologiesWeUsed:
            "Використані технології: Flutter, Dart, BLoC, Dio, Hive, Drift, Freezed, REST АРІ, Firebase, GitHub, Figma, Android Studio, Xсode.",
        whatWeDidTitle: "Реалізовано:",
        whatWeDidItems: [
            "У відповідності до бізнес-критеріїв розроблено структуру мобільного застосунку.",
            "Створено дизайн та функціонал проєкту відповідно до вимог замовника.",
            "Розроблено, протестовано та опубліковано мобільну програму.",
            "Приєднано мобільний застосунок до АРІ, відповідно до методології REST АРІ.",
            "Запроваджено безпеку та шифрування конфіденційних даних.",
        ],
    },
    testingForCivilServants: {
        info: {
            text1: 'Мобільні застосунки, що підтримуються iOS та Android, розроблено, для швидкої та ефективної підготовки до здачі тестового іспиту на знання законодавства. Для прийому на роботу в державні установи та силові структури. ',
            text2: 'Головна перевага тестів – вивчення матеріалу методом Лейтнера, особливістю якого є систематичне повторення важко запам\'ятовуючого матеріалу. ',
            text3: 'Окрім актуальних тестових питань, програма дає можливість доступу до статистики результатів пройдених тестів та пояснень. Запроваджено анонімний збір результатів, для аналізу та порівняння своєї ефективності з іншими учасниками тестування.',
        },
        technologiesWeUsed:
            "Використані технології:  Swift, Kotlin, SQLite, Android Studio, Xcode, PHP, MySQL.",
        whatWeDidTitle: "Реалізовано:",
        whatWeDidItems: [
            "Досліджено область подібних програм і сформовано індивідуальні особливості власної розробки",
            "Створено структуру та дизайн застосунків",
            "Сформовано та впроваджено глобальну базу даних, в тому числі API для завантаження тестів та зберігання статистики",
            "Дотримання усіх вимог замовника в розробці проєкта",
            "Протестовано та виправлено помилки програм",
            "Опубліковано програми в App Store та Play Market",
        ],
    },
    kavappAdmin: {
        info: {
            text1: 'Kavapp Admin — веб-панель адміністратора для управління кав’ярнями.',
            text2: 'Дозволяє створювати меню, інгредієнти, товари, нараховувати зарплату персоналу. Вбудована система аналітики для звітів про продажі, списання, оприбуткування. Має інструменти для управління акціями та знижками. Все це призначено для полегшення роботи адміністратора кав\'ярні.',
            text3: 'Забезпечує взаємодію з Kavapp Seller та Kavapp Delivery для комплексного управління бізнесом. Сайт також оптимізований для мобільних пристроїв, дозволяючи легко керувати всіма функціями адміністративної панелі через смартфон.',
        },
        technologiesWeUsed:
            "Використані технології: React, Redux, JavaScript, React Router, Redux Form, MUI, Axios, Sass, webpack, PHP, MySQL.",
        whatWeDidTitle: "Реалізовано:",
        whatWeDidItems: [
            "Сформовано структуру проєкту.",
            "Розроблено дизайн та функціонал адмін-панелі відповідно до бюджету та умов замовника.",
            "Створено та впроваджено АРІ",
            "Розроблено та протестовано Web-сайт",
            "Інтегровано податкову звітність в проєкт",
            "Запроваджено безпеку та шифрування конфіденційних даних",
        ],
    },
    coffeeMobile: {
        info: {
            text1: 'Мобільний застосунок орієнтований на клієнтів мережі кав’ярень Coffee Mobile. Основна мета програми – зручна та доступна дисконтна система для клієнтів.',
            text2: 'Користувачі програми, крім використання системи знижок та кешбеку з кожної покупки, можуть виконувати щотижневі та регулярні завдання. За проходження яких можна отримати додаткові бонуси. Дарувати бонуси іншим користувачам. Зарахувати решту на бонусний рахунок. Змінювати кольорову палітру застосунку. Переглядати статистику покупок та багато іншого.',
            text3: 'Застосунок сумісний з Android та iOS.',
        },
        technologiesWeUsed:
            "Використані технології: Flutter, Dart, Hive, REST АРІ, Firebase, Google Maps, Git, GitHub, Analytics, Figma, Android Studio, Xcode.",
        whatWeDidTitle: "Реалізовано:",
        whatWeDidItems: [
            "Створено структуру та дизайн проєкту.",
            "Підключено застосунок до програми обліку клієнтів на точках продажу",
            "Розроблено програму з урахуванням усіх вимог замовника (використовуючи Dart, Flutter, Hive)",
            "Під’єднано до API для швидкого оновлення завдань та зберігання статистики",
            "Протестовано та виправлено помилки застосунку",
            "Опубліковано програму в App Store та Play Market",
        ],
    },
    coffeeMobileAdmin: {
        info: {
            text1: 'Для керування мобільним застосунком Coffee Mobile було розроблено адмін-панель, яка дозволяє налаштовувати застосунок та змінювати інформацію всередині застосунку без його оновлення.',
            text2: 'Завдяки Coffee Mobile Admin є можливість редагувати банери, змінювати тимчасові завдання, добавляти чи видаляти позиціі товару в вбудованому магазині застосунку а також відповідати на повідомлення користувачів через месенджер служби підтримки.',
            text3: 'Адмін-панель зберігає інформацію по торгових точках та дає доступ до звітності продажів.'
        },
        technologiesWeUsed:
            "Використані технології: React, JavaScript, Redux, Redux Form, MUI, Axios, Sass, CSS, HTML, PHP, MySQL, Figma.",
        whatWeDidTitle: "Реалізовано:",
        whatWeDidItems: [
            "Створено структуру та дизайн проєкту.",
            "Розроблено та впроваджено глобальну базу даних та API для швидкого оновлення завдань та зберігання статистики (використовуючи PHP, MySQL).",
            "Розроблено відповідно до дизайну функціонал адмін-панелі для керування програмою (з використанням React, JavaScript, CSS та HTML).",
            "Протестовано та виправлено помилки панелі адміністратора",
        ],
    },
    kavappSite: {
        info: {
            text1: 'Для системи з автоматизації кав\'ярень Kavapp створено сайт, як платформу для залучення потенційних клієнтів та взаємодії з поточними.',
            text2: 'Користувачі вебсайту можуть ознайомиитись з усіма продуктами системи, в тому числі, з тарифами на запропоновані послуги. Для спілкування з клієнтами реалізовано легкий та зручний спосіб зворотнього зв\'язку, з можливістю вибору платформи для комунікації.',
            text3: 'Також, на сайті створено опцію миттєвої реєстрації в системі Kavapp та входу в особистий обліковий запис.'
        },
        technologiesWeUsed:
            "Використані технології: React, JavaScript, React Router DOM, CSS, Emotion, Axios",
        whatWeDidTitle: "Реалізовано:",
        whatWeDidItems: [
            "Розроблено прототип та фінальний дизайн.",
            "Продумано та створено функціонал виходячи з побажань замовника.",
            "Протестовано та виправлено помилки.",
            "Налаштувано домен та розміщено сайт на вебсервері.",
        ],
    },
    cmShopSite: {
        info: {
            text1: 'Для бренду Coffee Мobile (скорочено: CM) з метою автоматизації онлайн торгівлі було розроблено зручний та простий у користуванні інтернет-магазин.',
            text2: 'На сайті реалізовано функціонал, який охоплює основні бізнес-процеси торгівлі: каталог товарів (розбитий на групи та підгрупи, з можливістю додавання нових позицій та редагування старих), оформлення замовлень, вибір варіантів оплати і способів доставки, відстежування замовлень та управління запасами.',
            text3: 'Унікальною особливістю сайту є можливість вибору обсягів оптових закупівель, цінова політика яких змінюється відповідно до обраної кількості товару (чим більша сума замовлення - тим менша вартість товару). \n' +
                '\n' +
                'Налаштовано інтеграцію платіжних систем та продумано захист персональних даних.'
        },
        technologiesWeUsed:
            "Використані технології: Opencart, React, JavaScript, PHP, MySQL, Figma.",
        whatWeDidTitle: "Реалізовано:",
        whatWeDidItems: [
            "Розроблено архітектуру сайту.",
            "Дизайн та кольорову гаму створено з урахуванням усіх вимог замовника.",
            "Інтегровано інтернет-магазин в корпоративний сайт.",
            "Адаптовано сайт для мобільної та ПК версій.",
            "Реалізовано інтеграцію з платіжними системами.",
            "Вбудовано автоматичний експорт позицій магазину для Google Ads.",
            'Протестовано та розміщено на хостингу.'
        ],

    },
    button: 'Перейти до проєкту',
};

export default applications;
