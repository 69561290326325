const home = {
    bannerText:
        "Ми створюємо сучасні програми, які радують як клієнтів, так і користувачів",
    number_users: "задоволених користувачів",
    years: "років на ринку",
    number_projects: "успішно реалізованих проєктів",
    service: {
        mobile: {
            title: "Мобільні застосунки",
            text1:
                "В наш час мобільний застосунок є необхідністю для користувачів: клієнтів, співробітників або ділових партнерів. Це популярний інструмент для залучення нових та утримання постійних користувачів, підвищення рейтингу вашої компанії та популяризація бренду.",
            text2:
                "SoftWars надає високотехнологічні послуги в розробці мобільних застосунків, які дозволять вашому бізнесу вийти на новий рівень. Наші розробники – кваліфіковані фахівці, які створять надійний код, зручний інтерфейс та приємний дизайн вашого мобільного застосунку.",
            text3:
                "Наша компанія має багаторічний досвід у розробці застосунків, які використовують геолокацію, камеру, Bluetooth, сповіщення, медіа-файли, вбудовані покупки, АРІ та інший функціонал.",
            textAccent:
                "У розробці програм ми використовуємо: Flutter, Dart, Xcode, Android Studio ",
        },
        web: {
            title: "Web",
            text1:
                "Стрімка діджиталізація створює умови, за яких кожен бізнес, щоб успішно функціонувати, має обов’язково мати сайт, для отримання максимального прибутку і підвищення клієнтської лояльності.",
            text2:
                "Веброзробка має широкий спектр можливостей, та включає в себе: складні вебпрограми, інтернет-магазини, статичні вебсайти та API для мобільних застосунків. В залежності від функціоналу сайту, перед розробниками стоять різні завдання.",
            text3:
                "SoftWars успішно розробляє проєкти будь-якої складності як на стороні клієнта, так і на стороні сервера та має великий досвід веброзробки. Результатом чого є виконані, відповідно до найвищих стандартів, проєкти та задоволені замовники.",
            textAccent:
                "У веброзробці ми використовуємо: React, JavaScript, CSS, HTML, Node.js, PHP, Laravel, MySQL",
        },
        design: {
            title: "Дизайн",
            text1:
                "Дизайн проєкту оцінюється замовниками в першу чергу, і лише потім його функціональність. Приємний дизайн та зручний інтерфейс є найважливішими характеристиками мобільного або вебзастосунка.",
            text2:
                "SoftWars має великий досвід успішного використання дизайн системи Material Design, актуальних кольорових палітр, та вдало підібраної типографіки; розробки та створення індивідуальних логотипів та їх анімації.",
            text3:
                "Наші спеціалісти аналізують користувацькі сценарії, продумують логіку переходу зі сторінки на сторінку, що дозволяє досягати потрібної мети при використанні продукту максимально простим і зручним шляхом.",
            text4:
                "Ми використовуємо останні тенденції дизайну та дотримуємось принципів UI/UX, саме тому, гарантуємо якість виконаної нами роботи.",
            textAccent:
                "Для розробки дизайну ми використовуємо: Figma, Adobe Illustrator, Adobe Photoshop, Adobe After Effects",
        },
        serviceBtnTitle: "Переглянути",
    },
    meta: {
        title:
            "СофтВарс - створення сайтів та мобільна розробка, вебдизайн",
        description: "Компанія СофтВарс - створення сучасних сайтів, розробка мобільних застосунків, розробка вебдизайну для вашого проекту. Ми створюємо сучасні програми, які радують як клієнтів, так і користувачів"
    },
    portfolio: {
        viewMorePortfolioItemsBtn: "Переглянути ще",
    },
};

export default home;
