const web = {
    kavappAdmin:
        "Це вебсайт для управління та контролю всієї системи Kavapp. \n" +
        "\n" +
        "Через нього адміністратор може налаштовувати роботу кав'ярні, створювати меню, додавати інгредієнти та працівників, а також переглядати детальну звітність по бізнес-операціям.",
    coffeeMobileAdmin:
        "Панель адміністратора розроблено для керування мобільним застосунком Coffee Mobile.\n" +
        "\n" +
        "Адмін-панель дає можливість контролювати кожен аспект налаштувань, управляти технічною підтримкою; здійснювати контроль за позиціями та замовленнями вбудованого в застосунок інтернет-магазину.",
    cars: "Сайт для компанії з автовикупу розроблено з урахуванням потреб цільової аудиторії.\n" +
        "\n" +
        "Освновна мета вебсайту - донести користувачам інформацію про послуги, можливі варіанти співпраці, зручний та легкий спосіб зворотнього зв'язку та можливість попередньої оцінки авто клієнта.",
    kavappSite:
        "Цей сайт створений як вітрина для презентації можливостей системи Kavapp потенційним користувачам. \n" +
        "\n" +
        "Він надає докладну інформацію про особливості та переваги системи, демонструючи її вартість та функціонал. Якщо відвідувачі сайту зацікавлені у системі, вони можуть легко пройти реєстрацію та почати користування послугами Kavapp.",
    siteSmShop:
        "Для мережі кав’ярень Coffee Mobile було розроблено інтернет-магазин та інтегровано його з попередньо розробленим вебсайтом компанії.\n" +
        "\n" +
        "Повнофункціональний інтернет-магазин, з каталогом продукції і кошиком, дає можливість вибору і замовлення товару прямо на сайті, без обов'язкової реєстрації особистого кабінету покупця.",

};

export default web;
